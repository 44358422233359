import Modal from './../components/BsModal.vue'
import PaymentContainer from '../components/Checkout/PaymentContainer'
import Helpers from '../../../shared/helpers'

Vue.component('page-checkout', {

	components: {
		Modal,
		PaymentContainer
	},

	mixins: [Helpers],

	computed: {

		fraudData: function () {
			return window.fraud_data;
		},

		displayCredits() {
			if (!this.customer) {
				return false
			}

			if (!this.customer.credits) {
				return false
			}

			if (this.credits >= this.customer.credits) {
				return false
			}

			return true
		},

		allowedCredits() {
			if (!this.customer || !this.customer.credits) {
				return 0
			}

			return this.customer.credits
		}

	},

	watch: {
		coupon() {
			this.modalError = false
			this.modalErrorMessage = ''
		},

		billing: {
			handler: function () {
				let debouncedFunction = _.debounce(this.addBillingToSession.bind(this), 1000)
				debouncedFunction()
			},
			deep: true
		}
	},

	data: function () {
		return {
			showSummary: true,
			blackoutError: false,

			searchbox: {},

			expand_taxes: false,
			working: false,
			loading: false,

			modalErrorMessage: '',
			modalError: false,

			showCreditsForm: false,
			use_credits: null,

			terms_url: apr.terms_url,
			privacy_url: apr.privacy_url,

			displayParkingPerson: false,

			showCouponForm: false,
			coupon: apr.coupon,
			coupon_code: null,
			header_title: 'Guest details',
			customer_id: null,
			step: 1,
			customer: {},
			credits: apr.credits,
			billing: {
				email: '',
				firstname: '',
				lastname: '',
				zipcode: '',
				phone: '',
				license_plate: '',
			},
			card: {
				gateway_data: null
			},
			meta_data: {
				request_id: '',
				fraud_chance: '',
			},

			deviceData: null,

			search: apr.search,
			pricing: apr.pricing,
			parkinglot: apr.parkinglot,
			product: apr.product,

			errors: [],
			errorNoTimes: null,
			errorMessage: null,

			months: apr.months,
			years: apr.years,

			clone_search: {},

			times: apr.times,

			newsletter: true,
			sms: true,
			submitReservationDisabled: true
		}

	},

	mounted() {
		this.fireBeginCheckoutEvent();

		let self = this

		this.$root.$on('session-received', function (data) {

			if (data.login.id) {
				self.step = 2
				self.header_title = 'Customer details'
				self.customer_id = data.login.id
				self.customer = data.customer
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': 'CheckoutStep',
					'EventAction': 'Step',
					'EventLabel': '2'
				})

				//dataLayer - checkout step3 - Set Email
				let category = self.getLotCategory(apr.parkinglot.provider);
				let totalPrice = parseFloat(apr.pricing.total_str.replace("$", ""));
				let serviceFee = parseFloat(apr.pricing.servicefee_str.replace("$", ""));
				let taxesAndFess = apr.pricing.taxes_and_fess_total;
				let countDays = parseInt(apr.pricing.explanation);
				let price = parseFloat(((totalPrice - serviceFee - taxesAndFess)/countDays).toFixed(2));
				dataLayer.push({
					'event': 'checkout',
					'ecommerce': {
						'checkout': {
							'actionField': {'step': 3, 'option': 'Customer Details'},
							'products': [{
								'name': `${apr.search.airport.data.code} ${apr.parkinglot.name}`,
								'id': `${apr.search.airport.data.code}`,
								'price': price,
								'brand': '',
								'category': category,
								'variant': '',
								'quantity': 1
							}]
						}
					}
				})
			} else {
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': 'CheckoutStep',
					'EventAction': 'Step',
					'EventLabel': '1'
				})
			}

			if (apr.billing) {
				self.billing = apr.billing
			}
		})

		this.$root.$on('gateway-changed', (data) => {
			this.card = data
		});

		$('#Add_Coupon, #Add_Credits').on('hidden.bs.modal', function (e) {
			if (self.modalError) {
				this.cleanMessages()
				self.coupon = null
				self.credits = null
			}
		});

		$('#Edit_Times').on('hidden.bs.modal', function (e) {
			self.dismissTimesModal()
		});

		$('[data-toggle="popover"]').popover({
			html: true,
		});

		this.$root.$on(`checkout::credit-card-was-validated`, () => {
			this.onValidatedCard();
		});
	},

	methods: {
		registerBeginCheckoutEvent(action, label) {
			dataLayer.push({
				event: 'GaEvent',
				EventCategory: 'Begin Checkout',
				EventAction: action,
				EventLabel: label
			});
		},
		
		fireBeginCheckoutEvent() {
			let airportCode = apr.search.airport.data.code;

			dataLayer.push({
				event: 'begin_checkout',
				ecommerce: {
					currency: this.pricing.currency || null,
					value: this.pricing.pay_now || null,
					coupon: this.coupon_code || null,
					items: [
						{
							item_id: this.parkinglot.id || null,
							item_name: `${airportCode} ${this.parkinglot.name}`,
							coupon: this.coupon_code || null,
							discount: this.pricing.discount || null,
							index: null,
							item_brand:  this.parkinglot.name || null,
							item_category: null,
							item_category2: null,
							item_category3: null,
							item_category4: null,
							item_category5: null,
							item_variant: null,
							price: this.pricing.pay_now || null,
							quantity: 1
						}
					]
				}
			});
			this.registerBeginCheckoutEvent('Begin', 'Checkout');
		},

		showTimesModal() {
			this.clone_search = (JSON.parse(JSON.stringify(this.search)));
			$('#Edit_Times, #Select_Parking').modal('show')
		},

		dismissTimesModal() {
			for (const prop in this.clone_search) {
				if (this.clone_search[prop] != this.search[prop]) {
					this.search[prop] = this.clone_search[prop];
				}
			}
		},

		checkInput(e) {
			const NO_NUMBERS_ALLOWED_CHARS_REGEXP = /^([^0-9]*)$/;
			if (!NO_NUMBERS_ALLOWED_CHARS_REGEXP.test(e.key)) {
				e.preventDefault();
			}
		},
		submitReservation() {
			$('button').prop('disabled', true);
			this.loading = true;
			this.checkFraudChance(this.fraudData);
			this.$root.$emit('checkout::reservation-submitted', this.card);
			this.triggerCheckoutEvent('Complete Reservation')
		},

		onValidatedCard() {
			this.$http.post(apr.complete_reservation_link, {
				billing: this.billing,
				card: this.card,
				meta_data: this.meta_data,
				newsletter: this.newsletter,
				sms: this.sms,
			}).then((response) => {

				if (response.body.id) {
					//dataLayer - checkout step4 - Payment Method
					let category = this.getLotCategory(this.parkinglot.provider);
					let totalPrice = parseFloat(apr.pricing.total_str.replace("$", ""));
					let serviceFee = parseFloat(apr.pricing.servicefee_str.replace("$", ""));
					let taxesAndFess = apr.pricing.taxes_and_fess_total;
					let countDays = parseInt(apr.pricing.explanation);
					let price = parseFloat(((totalPrice - serviceFee - taxesAndFess)/countDays).toFixed(2));
					dataLayer.push({
						'event': 'checkout',
						'ecommerce': {
							'checkout': {
								'actionField': {'step': 4, 'option': 'Payment Method'},
								'products': [{
									'name': `${apr.search.airport.data.code} ${apr.parkinglot.name}`,
									'id': `${apr.search.airport.data.code}`,
									'price': price,
									'brand': '',
									'category': category,
									'variant': '',
									'quantity': 1
								}]
							}
						}
					})

					window.location.href = response.body.receipt_url
				} else {
					this.loading = false
				}

			}, (response) => {
				if (response.body.errors) {
					this.errors = response.body.errors

					if (this.errors.error !== undefined) {
						dataLayer.push({
							'event': 'GaEvent',
							'EventCategory': 'Checkout',
							'EventAction': 'Payment Gateway Error',
							'EventLabel': JSON.stringify(response.body.errors)
						});
						$("#modal-errors").modal("show")
					} else {
						dataLayer.push({
							'event': 'GaEvent',
							'EventCategory': 'Checkout',
							'EventAction': 'Validation error',
							'EventDetails': JSON.stringify(this.errors),
						});

						console.log(JSON.stringify(this.errors));
					}
				}

				if (response.body.error) {
					this.errorMessage = response.body.error[0]
					this.$root.$emit('payment-container::reset', this.card);

					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Checkout',
						'EventAction': 'Payment Gateway Error',
						'EventLabel': JSON.stringify(response.body.error)
					});
				}

				$('button').prop('disabled', false);
				this.loading = false
			})
		},

		updateTimes() {
			this.working = true
			this.errorTimes = false
			this.blackoutError = false
			this.$http.post(apr.update_reservation_link, {
				checkintime: this.search.checkintime.default,
				checkouttime: this.search.checkouttime.default,
				checkindate: this.search.checkindate.date_format_1,
				checkoutdate: this.search.checkoutdate.date_format_1,
			}).then((response) => {
				$('#Edit_Times').modal('hide')
				this.updatePage(response)
				this.showSummary = true
			}, (response) => {
				this.showSummary = false
				if (_.get(response, 'body.data.error') === 'times') {
					this.errorTimes = true
					this.errorCheckInTime = response.body.data.checkin
					this.errorCheckOutTime = response.body.data.checkout
				} else if (_.get(response, 'body.errors')) {
					this.errorTimes = true
					this.errorCheckInTime = _.get(response, 'body.errors.checkintime[0]')
					this.errorCheckOutTime = _.get(response, 'body.errors.checkouttime[0]')

					this.errors = _.reduce(_.get(response, 'body.errors', {}), function(result, value, key) {
						result.error.push(value[0]);
						return result;
					}, {error: []});
					$("#modal-errors").modal("show")
				} else {
					this.blackoutError = true
				}
				this.working = false
			})
		},

		nextStep() {
			if (this.isValidEmail()) {
				this.$http.post(apr.abandoned_cart_link, {email: this.billing.email})
				this.step++
				this.errors = []

				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': 'CheckoutStep',
					'EventAction': 'Step',
					'EventLabel': '2'
				})

				//dataLayer - checkout step3 - Set Email
				let category = this.getLotCategory(this.parkinglot.provider);
				let totalPrice = parseFloat(apr.pricing.total_str.replace("$", ""));
				let serviceFee = parseFloat(apr.pricing.servicefee_str.replace("$", ""));
				let taxesAndFess = apr.pricing.taxes_and_fess_total;
				let countDays = parseInt(apr.pricing.explanation);
				let price = parseFloat(((totalPrice - serviceFee - taxesAndFess)/countDays).toFixed(2));
				dataLayer.push({
					'event': 'checkout',
					'ecommerce': {
						'checkout': {
							'actionField': {'step': 3, 'option': 'Customer Details'},
							'products': [{
								'name': `${apr.search.airport.data.code} ${apr.parkinglot.name}`,
								'id': `${apr.search.airport.data.code}`,
								'price': price,
								'brand': '',
								'category': category,
								'variant': '',
								'quantity': 1
							}]
						}
					}
				})
			} else {
				this.errors = {'billing.email': 'Invalid email address.'}
			}
		},

		addBillingToSession() {
			this.$http.post(apr.add_billing_link, {billing: this.billing})
		},

		updatePage(response) {
			setTimeout(() => {
				this.search = response.body.search
				this.pricing = response.body.pricing
				this.coupon = response.body.coupon
				this.cleanMessages()
				this.working = false
			}, 1500)
		},

		applyCoupon() {

			if (this.coupon_code) {

				this.working = true
				this.$http.post(apr.add_coupon_link, {code: this.coupon_code}).then((response) => {
					this.coupon = this.coupon_code
					this.updatePage(response)
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Coupon',
						'EventAction': 'Success',
						'EventLabel': this.coupon_code
					})
				}, (response) => {
					this.modalErrors('Sorry that is an invalid coupon code')
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Coupon',
						'EventAction': 'Failed',
						'EventLabel': this.coupon_code
					})
				})

				return
			}

			this.modalErrors('Please enter a coupon code')
		},

		applyCredits() {

			if (this.use_credits) {
				this.working = true
				this.$http.post(apr.add_credits_link, {credits: this.use_credits}).then((response) => {
					this.updatePage(response)
					this.credits = this.use_credits
				}, (response) => {
					this.modalErrors('Sorry we are unable to add your credits.')
				})

				return
			}

			this.modalErrors('Please enter a credit amount you would like to use.')

		},

		cleanMessages() {
			this.working = false
			this.modalError = false
			this.modalErrorMessage = ''
		},

		modalErrors(message) {
			this.working = false
			this.modalError = true
			this.modalErrorMessage = message
		},

		isValidEmail() {
			return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(this.billing.email);
		},

		checkFraudChance(fraud_data) {
			if (!apr.device_fingerprint.enabled) {
				return;
			}

			let redirectPath = '/';

			if (typeof fraud_data !== "undefined" && fraud_data.request_id !== '' && fraud_data.fraud_chance !== '') {
				if (fraud_data.fraud_chance >= apr.device_fingerprint.fraud_mark) {
					window.location.href = redirectPath;
				}

				this.meta_data.request_id = String(fraud_data.request_id);
				this.meta_data.fraud_chance = String(fraud_data.fraud_chance);
			} else {
				window.location.href = redirectPath;
			}
		},

		triggerCheckoutEvent(action, label = '') {
			dataLayer.push({
				'event': 'GaEvent',
				'EventCategory': 'Checkout',
				'EventAction': action,
				'EventLabel': label
			});
		},

		toggleCoupon() {
			this.showCouponForm = !this.showCouponForm
		},

		toggleCredits() {
			this.showCreditsForm = !this.showCreditsForm
		},

		searchPath() {
			window.location.href = `/search/${this.search.airport.data.code}?token=${this.search.token}`
		},

		// checkoutLink(response)
		// {
		//     return response.receipt_url
		//     // return `/receipt/${response.body.id}/${response.body.receipt_token}`
		// },

		clearErrors(name) {
			this.errors[name] = null
		},

	}
});
