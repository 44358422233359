<template>
    <div class="apr-alert apr-alert--temporary-messaging">
        <div class="apr-alert__title" @click="toggle()">
            <i class="apr-icon-error"></i>
            <span>{{title}}</span>
            <i class="fas fa-chevron-down" :class="{active: activeContent}"></i>
        </div>
        <div class="apr-alert__content" :class="{active: activeContent}" v-html="content"></div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        content: {
            type: String,
            default: '',
        },
        activeContent: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        toggle() {
            this.activeContent = !this.activeContent;
        }
    }
}
</script>

<style scoped lang="scss">
.apr-alert {
    border: 1px solid #1776CE;
    background: #1776CE1A;
    color: #1776CE;
    gap: 0;
    margin-top: 8px;

    &__title {
        align-items: center;
        span {
            flex: 1 1 0;
        }
    }

    &__content {
        display: none;
        overflow: auto;
        &.active {
            margin-top: 32px;
            display: block;
        }
    }

    .apr-icon-error,
    .fa-chevron-down {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px 8px 0 0;
    }

    .fa-chevron-down {
        transition: transform 0.5s;
        &.active {
            transform: rotate(180deg);
        }
    }
}
</style>